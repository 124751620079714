import React from "react";

import "keen-slider/keen-slider.min.css";
import "./src/static/styles/app.scss";

import { PopupProvider } from "@/contexts/PopUp";
import { LocationProvider } from "@/contexts/Location";

export const wrapRootElement = ({ element }) => (
  <LocationProvider>
    <PopupProvider>{element}</PopupProvider>
  </LocationProvider>
);