import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";

interface LocationContextProps {
  locationBase: string;
}

const LocationContext = createContext<LocationContextProps | undefined>(
  undefined
);

export const LocationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [locationBase, setLocationBase] = useState<string>("");
  const spanishPaths = ["santiago-de-chile", "bogota", "lima", "buenos-aires"];

  useEffect(() => {
    const path = window.location.pathname;
    const pathSegments = path.split("/").filter(Boolean);
    const base = pathSegments[0] || "";
    setLocationBase(base);
  }, []);

  return (
    <LocationContext.Provider value={{ locationBase }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = (): LocationContextProps => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error(
      "useLocationContext must be used within a LocationProvider"
    );
  }
  return context;
};
